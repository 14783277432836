var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "dn-video" + (_vm.playing ? " dn-video--playing" : "") },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transition" } },
        [
          _c("back-button", {
            staticClass: "dn-video__back",
            on: {
              back: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { ref: "videobox", staticClass: "dn-video__player-container" },
        [
          _vm.video.exclusive && !_vm.userEntitledExclusive
            ? [
                _c(
                  "div",
                  { staticClass: "video-placeholder elevation-10" },
                  [
                    _c("v-img", { attrs: { src: _vm.video.thumbnailUrl } }),
                    _c("v-btn", { attrs: { text: "", to: "/login" } }, [
                      _vm._v("Sign in or Upgrade to access exclusive content"),
                    ]),
                  ],
                  1
                ),
              ]
            : [
                _vm.loaded
                  ? _c("video-player", {
                      attrs: { options: _vm.videoOptions, "align-center": "" },
                      on: { play: _vm.togglePlay, pause: _vm.togglePlay },
                    })
                  : _vm._e(),
              ],
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "dn-video__meta" },
        [
          _c("h1", { staticClass: "dn-video__title" }, [
            _vm._v(_vm._s(_vm.video.title)),
          ]),
          _c("tag-list", { attrs: { list: _vm.video.tags } }),
          _c("p", [_vm._v(_vm._s(_vm.video.description))]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }